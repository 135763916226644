import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../../services/cart.service';
import { FavoriteService } from '../../services/favorite.service';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input() product;
  @Input() wish: boolean;
  @Output() triggerFavs = new EventEmitter();
  @Output() triggerCart = new EventEmitter();
  @Output() toastMessage = new EventEmitter();
  @Output() triggerWishlist = new EventEmitter();

  constructor(
    private cartService: CartService,
    private favoriteService: FavoriteService,
    private _router: Router
  ) { }

  ngOnInit(): void {
  }

  addToFavorites(id, productInfo) {
    this.favoriteService.addFavoriteLocal(id, productInfo);
    this.triggerFavs.emit();
  }

  removeFromFavorites(id) {
    this.favoriteService.removeFavoriteLocal(id);
    this.triggerFavs.emit();
    // if (this.wish) {
    //   this.removeWishList(id);
    // }
  }

  async add(product) {
    const result = await this.cartService.addToCart(product);
    result.success ?
      this.toastMessage.emit({ severity: 'success', summary: 'Success', detail: 'The product has been successfully added to your cart' }) :
      this.toastMessage.emit({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });
    if (this.wish) {
      this.triggerCart.emit();
    }
  }

  removeWishList(id) {
    this.triggerWishlist.emit(id);
    this.removeFromFavorites(id);
  }
  handleClick(): void {
    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
      }
    this._router.onSameUrlNavigation = 'reload';
  }

}
