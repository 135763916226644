import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Error404PageComponent } from './pages/error404-page/error404-page.component';
import { RoutesConfig } from './configs/routes.config';
import { MainLayoutComponent } from './shared/layout/main-layout/main-layout.component';
import { AuthLayoutComponent } from './shared/layout/auth-layout/auth-layout.component';
import { WallOfFameLayoutComponent } from './shared/layout/wall-of-fame-layout/wall-of-fame-layout.component';
import { AuthGuard } from './modules/auth/shared/auth.guard';

const routesNames = RoutesConfig.routesNames;

const routes: Routes = [
  {
    path: routesNames.home,
    component: WallOfFameLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {

        path: '',
        loadChildren: () => import('./modules/wall-of-fame/wall-of-fame.module').then(m => m.WallOfFameModule),
        pathMatch: 'full',
      },
    ],
  },
  {
    path: routesNames.locker.basePath,
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/locker/locker.module').then(m => m.LockerModule),
      },
    ],
  },
  {
    path: routesNames.achievement.basePath,
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/achievement/achievement.module').then(m => m.AchievementModule),
      },
    ],
  },
  {
    path: routesNames.promotion.basePath,
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/promotion/promotion.module').then(m => m.PromotionModule),
      },
    ],
  },
  {
    path: routesNames.sponsor.basePath,
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/sponsor/sponsor.module').then(m => m.SponsorModule),
      },
    ],
  },
  {
    path: routesNames.shop.basePath,
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/shop/shop.module').then(m => m.ShopModule),
      },
    ],
  },
  {
    path: routesNames.cart.basePath,
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/cart/cart.module').then(m => m.CartModule),
      },
    ],
  },
  {
    path: routesNames.ticket.basePath,
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/ticket/ticket.module').then(m => m.TicketModule),
      },
    ],
  },
  {
    path: routesNames.clubtv.basePath,
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/clubtv/clubtv.module').then(m => m.ClubtvModule),
      },
    ],
  },
  {
    path: routesNames.inbox.basePath,
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/inbox/inbox.module').then(m => m.InboxModule),
      },
    ],
  },
  {
    path: routesNames.auth.basePath,
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
      },
    ],
  },
  {
    path: routesNames.user.basePath,
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
      },
    ],
  },
  {
    path: routesNames.squad.basePath,
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/squad/squad.module').then(m => m.SquadModule),
      },
    ],
  },
  
  { path: routesNames.error404, component: Error404PageComponent },

  { path: 'en', redirectTo: '' }, // because english language is the default one

  // otherwise redirect to 404
  { path: '**', redirectTo: RoutesConfig.routes.error404 },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy',
      useHash: true
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
