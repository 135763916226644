import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app'; import 'firebase/auth';
import { CookieService } from '@gorniv/ngx-universal';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedIn = new BehaviorSubject<any>(null);

  supportedPopupSignInMethods = [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.GithubAuthProvider.PROVIDER_ID,
  ];

  constructor(
    private http: HttpClient,
    private firebaseAuth: AngularFireAuth,
    private cookieService: CookieService,
  ) {
    if (this.loggedIn.value === null) {
      if (this.cookieService.get('username')) {
        this.loggedIn.next(this.cookieService.get('username'));
      }
    }
  }

  registerWithMail = async (formValue: { name: string, email: string, password: string }) => {
    try {
      const signResult = await this.firebaseAuth.createUserWithEmailAndPassword(formValue.email, formValue.password);
      const user = signResult.user;
      console.log(formValue);
      await user.updateProfile({
        displayName: formValue.name,
      });
      user.sendEmailVerification();
      // zoho entegrasyonu eklenecek...
      // if (signResult.additionalUserInfo.isNewUser && !driverData) {
      //     await this.createZohoCustomerUser(user);
      // }
      return {
        success: true,
        message: 'Your account was successfully created! Now you need to verify your e-mail address, please go check your inbox.'
      };
    } catch (err) {
      let message = `Something went wrong, we couldn't create your account. Please try again.`;
      if (err.code === 'auth/email-already-in-use') {
        message = err.message;
      }
      return {
        success: false,
        message
      };
    }

  }

  loginWithMail = async (formValue: { email: string, password: string }) => {
    try {
      const result = await this.firebaseAuth.signInWithEmailAndPassword(formValue.email, formValue.password);
      const user = result.user;
      if (user.emailVerified) {
        const token = await user.getIdToken();
        this.cookieService.put('uid', user.uid);
        this.cookieService.put('token', JSON.stringify(token));
        this.cookieService.put('username', user.displayName);
        this.sendLoginInfo(user.displayName);
        return {
          success: true,
          message: 'Welcome'
        };
      } else {
        return {
          success: false,
          message: 'Please verify your email address'
        };
      }
    } catch (err) {
      return {
        success: false,
        message: `Something went wrong, The email address or password is incorrect. Please try again.`
      };
    }
  }

  loginWithGoogle = async () => {
    try {
      const signResult = await this.firebaseAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
      const user = signResult.user;
      // zoho entegre edilcek...
      // if (signResult.additionalUserInfo.isNewUser) {
      //     await this.createZohoCustomerUser(user);
      // }
      const token = await user.getIdToken();
      this.cookieService.put('uid', user.uid);
      this.cookieService.put('token', JSON.stringify(token));
      this.cookieService.put('username', user.displayName);
      this.sendLoginInfo(user.displayName);
      return { success: true };
    } catch (err) {
      return {};
    }
  }

  loginWithFacebook = async () => {
    try {
      const signResult = await this.firebaseAuth.signInWithPopup(new firebase.auth.FacebookAuthProvider());
      const user = signResult.user;
      // zoho entegre edilcek...
      // if (signResult.additionalUserInfo.isNewUser) {
      //     await this.createZohoCustomerUser(user);
      // }
      const token = await user.getIdToken();
      this.cookieService.put('uid', user.uid);
      this.cookieService.put('token', JSON.stringify(token));
      this.cookieService.put('username', user.displayName);
      this.sendLoginInfo(user.displayName);
      return { success: true };
    } catch (err) {
      if (err.email && err.credential && err.code === 'auth/account-exists-with-different-credential') {
        const providers = await firebase.auth().fetchSignInMethodsForEmail(err.email);
        const firstPopupProviderMethod = providers.find(p => this.supportedPopupSignInMethods.includes(p));
        // Test: Could this happen with email link then trying social provider?
        if (!firstPopupProviderMethod) {
          return { success: false, credential: null };
        } else {
          return { success: false, credential: err.credential };
        }
      } else {
        return {};
      }
    }
  }

  loginWithGoogleandLinkWith = async (credential) => {
    try {
      await this.firebaseAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
      const user = firebase.auth().currentUser;

      const providers = await firebase.auth().fetchSignInMethodsForEmail(user.email);
      const firstPopupProviderMethod = providers.find(p => this.supportedPopupSignInMethods.includes(p));

      // Test: Could this happen with email link then trying social provider?
      if (!firstPopupProviderMethod || firstPopupProviderMethod !== firebase.auth.GoogleAuthProvider.PROVIDER_ID) {
        throw new Error(`Your account is linked to a provider that isn't supported.`);
      }

      await user.linkWithCredential(credential);

      const token = await user.getIdToken();
      this.cookieService.put('token', JSON.stringify(token));
      this.cookieService.put('username', user.displayName);
      this.sendLoginInfo(user.displayName);
      return { success: true };
    } catch (error) {
      return {};
    }
  }

  async activateAccount(actionCode) {
    try {
      const resp = await firebase.auth().applyActionCode(actionCode);
      return { status: true, data: resp };
    } catch (err) {
      return { status: false, message: err.message };
    }
  }

  sendLoginInfo(username) {
    this.loggedIn.next(username);
  }

  getLoginInfo(): Observable<any> {
    return this.loggedIn.asObservable();
  }

  isLoggedIn(): boolean {
    const user = this.cookieService.get('token');
    if (user) {
      return true;
    } else {
      return false;
    }

  }

  resetPasswordWithMail = async (formValue: { email: string }) => {
    try {
      await this.firebaseAuth.sendPasswordResetEmail(formValue.email);
      return {
        success: true,
        message: 'The reset link has been successfully sent to your mail, please go check your inbox.'
      };
    } catch (err) {
      return {
        success: false,
        message: `Email not found.`
      };
    }
  }

  resetPassword = async (code, formValue: { password: string }) => {
    try {
      await this.firebaseAuth.confirmPasswordReset(code, formValue.password);
      return {
        success: 'success',
        message: 'Your password was successfully reset.'
      };
    } catch (err) {
      return {
        success: 'error',
        message: `Something went wrong, please try again.`
      };
    }
  }

  verifyEmail = async (verifyCode) => {
    try {
      await this.firebaseAuth.applyActionCode(verifyCode);
      return {
        success: true,
        message: 'Your email was successfully verified.'
      };
    } catch (err) {
      return {
        success: false,
        message: `Something went wrong, please try again.`
      };
    }
  }

  logOut = async () => {
    try {
      await this.firebaseAuth.signOut();
      return {
        success: true,
      };
    } catch (err) {
      return {
        success: false,
      };
    }
  }

  // createZohoCustomerUser(user: firebase.User) {
  //     return new Promise((resolve, reject) => {
  //         const displayName = (user.displayName || ' ').split(' ');
  //         const firstName = displayName[0];
  //         const lastName = displayName[1];
  //         this.http.post<string>(environment.apiUrl + `/api/createCustomerUser`,
  //             { uid: user.uid, firstName, lastName, email: user.email }).toPromise().then(res => {
  //                 if (res['code'] === 'success') {
  //                     resolve(res);
  //                 } else {
  //                     reject(res);
  //                 }
  //             }, err => {
  //                 reject(err);
  //             });

  //     });
  // }
}
