import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  placeOrder: Subject<any> = new Subject();
  placeOrderReceived = this.placeOrder.asObservable();

  itemValue = new BehaviorSubject(this.theItem);

  constructor() { }

  get theItem() {
    return JSON.parse(localStorage.getItem('cart'));
  }

  async addToCart(product) {
    try {
      if (!product.quantily) {
        product.quantily = 1;
      }
      let cartTemp: any = [];
      JSON.parse(localStorage.getItem('cart')) ? cartTemp = JSON.parse(localStorage.getItem('cart')) : cartTemp = [];
      if (cartTemp.length > 0) {
        cartTemp.map(el => {
          if (product.id === el.id) {
            this.removeFromCart(product.id);
            product.quantily++;
          }
        });
      }
      let cart: any = [];
      JSON.parse(localStorage.getItem('cart')) ? cart = JSON.parse(localStorage.getItem('cart')) : cart = [];
      const exist = cart.some(el => el.id === product.id);
      if (!exist) {
        cart.push(product);
        await localStorage.setItem('cart', JSON.stringify(cart));
        this.itemValue.next(cart);
      }
      return {
        success: true,
      };
    } catch (err) {
      return {
        success: false,
      };
    }
  }
  updateQuanlity(product, quantily) {
    let cartTemp: any = [];
    JSON.parse(localStorage.getItem('cart')) ? cartTemp = JSON.parse(localStorage.getItem('cart')) : cartTemp = [];
    if (cartTemp.length > 0) {
      cartTemp.map(el => {
        if (product.id === el.id) {
          this.removeFromCart(product.id);
          product.quantily = quantily;
        }
      });
      let cart: any = [];
      JSON.parse(localStorage.getItem('cart')) ? cart = JSON.parse(localStorage.getItem('cart')) : cart = [];
      const exist = cart.some(el => el.id === product.id);
      if (!exist) {
        cart.push(product);
        localStorage.setItem('cart', JSON.stringify(cart));
        this.itemValue.next(cart);
      }
    }
  }
  removeFromCart(id) {
    const cart = JSON.parse(localStorage.getItem('cart'));
    if (cart) {
      let index: number;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < cart.length; i++) {
        const product = cart[i];
        if (product.id === id) {
          index = i;
        }
      }
      if (index > -1) {
        cart.splice(index, 1);
        localStorage.setItem('cart', JSON.stringify(cart));
        this.itemValue.next(cart);
      }
    }
  }

}
