import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ROUTES_CONFIG } from '../../../configs/routes.config';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})

export class SearchBarComponent implements OnInit {

  heroFormControl: FormControl;
  filteredHeroes: any;

  constructor(
    @Inject(ROUTES_CONFIG) public routesConfig: any) {
    this.heroFormControl = new FormControl();
  }

  ngOnInit() {

  }

  // filterHeroes(val: string): Hero[] {
  //   return val ? this.defaultHeroes.filter(hero => hero.name.toLowerCase().indexOf(val.toLowerCase()) === 0 && hero.default)
  //     : this.defaultHeroes;
  // }
}
