import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { APP_CONFIG } from '../../../configs/app.config';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

import { CookieService } from '@gorniv/ngx-universal';
import { ProgressBarService } from '../../../modules/core/services/progress-bar.service';
import { AuthService } from 'src/app/modules/auth/shared/auth.service';
import { LoadingService } from 'src/app/modules/core/services/loading.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CartService } from '../../services/cart.service';

// const THEME_DARKNESS_SUFFIX = `-dark`;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})



export class HeaderComponent implements OnInit, OnDestroy {
  // activeTheme: string;
  // themes: string[] = [
  //   'deeppurple-amber',
  //   'indigo-pink',
  //   'pink-bluegrey',
  //   'purple-green',
  // ];
  // isThemeDark = false;
  // @HostBinding('class') activeThemeCssClass: string;
  screenHeight: number;
  screenWidth: number;
  selectedLanguage: string;
  progressBarMode: string;
  currentUrl: string;
  languages: any[];
  username = null;
  isChecked = false;
  subscription: Subscription;
  SearchClick = false;

  rightMenu;
  leftMenu;
  cartSidebar;
  cartProducts = [];
  display;

  lang = [
    { label: 'EN', value: 'en' },
    { label: 'TR', value: 'tr' },
    { label: 'DE', value: 'de' },
    { label: 'ES', value: 'es' },
    { label: 'PT', value: 'pt' }
  ];
  currency = [
    { label: 'EUR €', value: 'euro' },
    { label: 'USD $', value: 'usd' },
  ];
  constructor(
    @Inject(APP_CONFIG) public appConfig: any,
    private progressBarService: ProgressBarService,
    private cookieService: CookieService,
    public router: Router,
    private authService: AuthService,
    private cartService: CartService,
    private loading: LoadingService,
    private overlayContainer: OverlayContainer
  ) {
    this.languages = [{ name: 'en', label: 'English' }, { name: 'es', label: 'Español' },
    { name: 'tr', label: 'Turkish' }, { name: 'de', label: 'German' }, { name: 'pt', label: 'Portuguese' }];
  }

  ngOnInit() {
    this.onResize();
    this.subscription = this.authService.getLoginInfo().subscribe(username => {
      this.username = username;
    });
    this.cartService.itemValue.subscribe((nextValue) => {
      if (nextValue) {
        this.cartProducts = nextValue;
      }
    });
    this.selectedLanguage = this.cookieService.get('language') || 'en';
    this.progressBarService.getUpdateProgressBar().subscribe((mode: string) => {
      this.progressBarMode = mode;
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }

  // setActiveTheme(theme: string, darkness: boolean = null) {
  //   if (darkness === null) {
  //     darkness = this.isChecked;
  //   }
  //   else if (this.isChecked === darkness) {
  //     if (this.activeTheme === theme) { return; }
  //   } else {
  //     this.isChecked = darkness;
  //   }
  //   this.activeTheme = theme;

  //   const cssClass = darkness === true ? theme + THEME_DARKNESS_SUFFIX : theme;

  //   const classList = this.overlayContainer.getContainerElement().classList;
  //   if (classList.contains(this.activeThemeCssClass)) {
  //     classList.replace(this.activeThemeCssClass, cssClass)
  //   }
  //   else {
  //     classList.add(cssClass);
  //   }
  //   this.activeThemeCssClass = cssClass;
  // }

  // toggleDarkness() {
  //   this.setActiveTheme(this.activeTheme, !this.isThemeDark)
  // }

  changeLanguage(language: string): void {
    this.cookieService.put('language', language);
    this.selectedLanguage = language;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  SearchActive(){
    if(this.SearchClick){
      this.SearchClick = false;
    }
    else{
      this.SearchClick = true;
    }
  }
}
