import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sponsor-side',
  templateUrl: './sponsor-side.component.html',
  styleUrls: ['./sponsor-side.component.scss']
})
export class SponsorSideComponent implements OnInit {
  @Input() side;

  matches = [
    {
      id: 14, day: '02', date: 'MAY', time: '20:00',
      secondTeam: { name: 'REAL MADRID', logo: 'madrid' },
      firstTeam: { name: 'FC Fannector', logo: 'fannector' },
    },
    {
      id: 15, day: '09', date: 'MAY', time: '20:00',
      firstTeam: { name: 'FENERBAHCE', logo: 'fb' },
      secondTeam: { name: 'FC Fannector', logo: 'fannector' },
    },
    {
      id: 17, day: '23', date: 'MAY', time: '20:00',
      firstTeam: { name: 'FC CHELSEA', logo: 'chelsea' },
      secondTeam: { name: 'FC Fannector', logo: 'fannector' },
    }
  ];

  events = [
    {
      day: 'O8', date: 'Wed', time: '10:00 – 11:30am',
      header: 'Open Training',
      content: 'Watch our team get ready for our next match',
      location: 'Fannector Arena', fans: '300 fans going'
    },
    {
      day: '16', date: 'Thu', time: '01:00 – 02:00pm',
      header: 'Player Live Video Session',
      content: 'Diego Maradona will be ready to answer all your questions ',
      location: 'Video Session', fans: '1.5k fans attend'
    },
    {
      day: '24', date: 'Fri', time: '03:00 – 06:00pm',
      header: 'Signing Session',
      content: 'Get your autographs from your favorite players and take a picture with them.',
      location: 'Fannector Arena', fans: '1.250 fans going'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
