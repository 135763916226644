import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-mobile-sidebar',
  templateUrl: './mobile-sidebar.component.html',
  styleUrls: ['./mobile-sidebar.component.scss']
})
export class MobileSidebarComponent implements OnInit {
  @Output() closeSidebar = new EventEmitter();

  lang = [
    { label: 'EN', value: 'en' },
    { label: 'TR', value: 'tr' },
  ];
  currency = [
    { label: 'EUR €', value: 'euro' },
    { label: 'USD $', value: 'usd' },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
