import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from '@gorniv/ngx-universal';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/modules/auth/shared/auth.service';
import { LoadingService } from 'src/app/modules/core/services/loading.service';

@Component({
  selector: 'app-user-settings-panel',
  templateUrl: './user-settings-panel.component.html',
  styleUrls: ['./user-settings-panel.component.scss']
})
export class UserSettingsPanelComponent implements OnInit {

  @Output() redirect = new EventEmitter();
  username = null;
  subscription: Subscription;

  constructor(
    public router: Router,
    private loading: LoadingService,
    private authService: AuthService,
    private cookieService: CookieService,
  ) { }

  ngOnInit(): void {
    this.subscription = this.authService.getLoginInfo().subscribe(username => {
      this.username = username;
    });
  }

  async logOut() {
    this.loading.show();
    this.router.navigateByUrl('/auth/sign-in');
    this.username = false;
    this.authService.sendLoginInfo('');
    const result = await this.authService.logOut();
    this.loading.hide();
    this.cookieService.remove('token');
    this.cookieService.remove('username');
  }
}
