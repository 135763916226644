import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { APP_CONFIG } from '../../../configs/app.config';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/modules/auth/shared/auth.service';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {
  @Output() closeSidebar = new EventEmitter();

  username = null;
  subscription: Subscription;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.subscription = this.authService.getLoginInfo().subscribe(username => {
      this.username = username;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
