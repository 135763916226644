import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {

  constructor(
    private http: HttpClient
  ) { }

  addFavoriteLocal(id, product) {
    let favorites: any = [];
    JSON.parse(localStorage.getItem('favorites')) ? favorites = JSON.parse(localStorage.getItem('favorites')) : favorites = [];
    const exist = favorites.some(el => el === id);
    console.log(exist);
    if (!exist) {
      favorites.push(id);
      localStorage.setItem('favorites', JSON.stringify(favorites));
    }
    this.addWishList(product);
  }

  removeFavoriteLocal(id) {
    const favorites = JSON.parse(localStorage.getItem('favorites'));
    if (favorites) {
      const index = favorites.indexOf(id);
      if (index > -1) {
        favorites.splice(index, 1);
        localStorage.setItem('favorites', JSON.stringify(favorites));
      }
    }
    this.removeWishList(id);
  }

  addWishList(product) {
    let wishList: any = [];
    JSON.parse(localStorage.getItem('wishList')) ? wishList = JSON.parse(localStorage.getItem('wishList')) : wishList = [];
    const exist = wishList.some(el => el.id === product.id);
    if (!exist) {
      wishList.push(product);
      localStorage.setItem('wishList', JSON.stringify(wishList));
    }
  }

  removeWishList(id) {
    const wishList = JSON.parse(localStorage.getItem('wishList'));
    if (wishList) {
      let index: number;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < wishList.length; i++) {
        const product = wishList[i];
        if (product.id === id) {
          index = i;
        }
      }
      if (index > -1) {
        wishList.splice(index, 1);
        localStorage.setItem('wishList', JSON.stringify(wishList));
      }
    }
  }

}



