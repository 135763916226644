import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-shop-card',
  templateUrl: './shop-card.component.html',
  styleUrls: ['./shop-card.component.scss'],

})
export class ShopCardComponent {


  @Input() slider;
  products = [
    { name: 'FC Fannector Ball', img: 'ball', price: '15', oldPrice: '30' },
    { name: 'FC Fannector Hoodie', img: 'sweatshirt', price: '20', oldPrice: '40' },
    { name: 'FC Fannector Cap', img: 'cap', price: '17.50', oldPrice: '35' },
    { name: 'FC Fannector Jacket', img: 'jacket', price: '35', oldPrice: '70' },
    { name: 'FC Fannector Jacket', img: 'jacket3', price: '15', oldPrice: '70' },
    { name: 'FC Fannector Jacket', img: 'jacket2', price: '22', oldPrice: '50' },
  ];

  // card componentindeki slider goruntulerini ve butonlarin stiliyle oynamayi degistirmek icin.

  @Input() sliderWish;
  productsWishs = [
    { name: 'FC Fannector Jacket', img: 'jacket3', price: '35', oldPrice: '70' },
    { name: 'FC Fannector Hoodie', img: 'sweatshirt', price: '20', oldPrice: '40' },
    { name: 'FC Fannector Jacket', img: 'jacket2', price: '17.50', oldPrice: '35' },
    { name: 'FC Fannector Jacket', img: 'black-orange-jacket', price: '35', oldPrice: '70' },
    { name: 'FC Fannector Ball', img: 'ball', price: '15', oldPrice: '30' },
    { name: 'FC Fannector Cap', img: 'cap', price: '17.50', oldPrice: '35' },

  ];

  shopProducts = [
    { name: 'FC Fannector Jacket', img: 'jacket', price: '45', oldPrice: '90' },
    { name: 'FC Fannector Match Day Football', img: 'ball', price: '25', oldPrice: '50' },
    { name: 'FC Fannector Hoodie', img: 'sweatshirt', price: '30', oldPrice: '60' },
    { name: 'FC Fannector Cap', img: 'cap', price: '15', oldPrice: '30' },
    { name: 'FC Fannector Womens Polo Shirt', img: '17', price: '25', oldPrice: '50' },
    { name: 'FC Fannector Backpack', img: '16', price: '17,50', oldPrice: '35' },
    { name: 'FC Fannector Sport Bag', img: '15', price: '25', oldPrice: '50' },
    { name: 'FC Fannector Womens T-Shirt', img: '19', price: '20', oldPrice: '40' },
    { name: 'FC Fannector Mens T-Shirt', img: '20', price: '20', oldPrice: '40' },
    { name: 'FC Fannector Orange Training T-Shirt', img: '21', price: '20', oldPrice: '40' },
    { name: 'FC Fannector Mens Polo Shirt', img: '22', price: '25', oldPrice: '50' },
    { name: 'FC Fannector Blue Training Shirt', img: '23', price: '30', oldPrice: '60' },
    { name: 'FC Fannector Blue Wind Jacket Shirt', img: '24', price: '40', oldPrice: '80' },
    { name: 'FC Fannector Match Day Polo Tshirt', img: '2', price: '30', oldPrice: '60' },
    { name: 'FC Fannector Body Vest', img: '3', price: '20', oldPrice: '40' },
    { name: 'FC Fannector Orange Tracksuit', img: '6', price: '50', oldPrice: '100' },
    { name: 'FC Fannector Blue Training Jacket', img: '5', price: '35', oldPrice: '70' },
    { name: 'FC Fannector Blue Tracksuit', img: '4', price: '50', oldPrice: '100' },
    { name: 'FC Fannector Blue Womens Polo Shirt', img: '9', price: '25', oldPrice: '50' },
    { name: 'FC Fannector Orange Training Jacket', img: '8', price: '35', oldPrice: '70' },
    { name: 'FC Fannector Blue Polo Shirt', img: '7', price: '35', oldPrice: '70' },
    { name: 'FC Fannector Orange Wind Jacket', img: '12', price: '25', oldPrice: '50' },
    { name: 'FC Fannector Fan Football', img: '11', price: '40', oldPrice: '80' },
    { name: 'FC Fannector Orange Match Day Jacket', img: '10', price: '45', oldPrice: '90' },
    { name: 'FC Fannector Blue Football', img: '14', price: '30', oldPrice: '60' },
    { name: 'FC Fannector Blue Match Jacket', img: '18', price: '30', oldPrice: '60' },
    { name: 'FC Fannector Winter Jacket', img: '13', price: '50', oldPrice: '100' },
  ];

  responsiveOptions = [];
  constructor() {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

}
