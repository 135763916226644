import { ShopCardComponent } from './components/shop-card/shop-card.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { FooterComponent } from './components/footer/footer.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { HeaderComponent } from './components/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxExampleLibraryModule } from '@ismaestro/ngx-example-library';
import { NgxScrollToFirstInvalidModule } from '@ismaestro/ngx-scroll-to-first-invalid';
import { LoadingPlaceholderComponent } from './components/loading-placeholder/loading-placeholder.component';
import { CapitalizeFirstPipe } from './pipes/capitalize-first.pipe';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { LoadingComponent } from './components/loading/loading.component';
import { BaseComponent } from './components/base/base.component';
import { AlphabetOnlyDirective } from './directives/alphabet-only.directive';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';

//#region Primeng

import { SidebarModule } from 'primeng/sidebar';
import { CarouselModule } from 'primeng/carousel';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { BadgeModule } from 'primeng/badge';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
//#endregion

import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { AuthFooterComponent } from './components/auth-footer/auth-footer.component';
import { WallOfFameLayoutComponent } from './layout/wall-of-fame-layout/wall-of-fame-layout.component';
import { SponsorSideComponent } from './components/sponsor-side/sponsor-side.component';
import { UserSettingsPanelComponent } from './components/user-settings-panel/user-settings-panel.component';
import { MobileSidebarComponent } from './components/mobile-sidebar/mobile-sidebar.component';
import { ProductCardComponent } from './components/product-card/product-card.component';

import { CartSidebarComponent } from './components/cart-sidebar/cart-sidebar.component';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    SidebarModule,
    NgxExampleLibraryModule,
    NgxScrollToFirstInvalidModule,
    LazyLoadImageModule,
    CarouselModule,
    DropdownModule,
    AccordionModule,
    OverlayPanelModule,
    ToastModule,
    BadgeModule
  ],
  declarations: [
    ShopCardComponent,
    HeaderComponent,
    SidenavComponent,
    SearchBarComponent,
    FooterComponent,
    SpinnerComponent,
    LoadingPlaceholderComponent,
    CapitalizeFirstPipe,
    MainLayoutComponent,
    AuthLayoutComponent,
    MessageModalComponent,
    LoadingComponent,
    BaseComponent,
    AlphabetOnlyDirective,
    NumbersOnlyDirective,
    AuthLayoutComponent,
    AuthFooterComponent,
    WallOfFameLayoutComponent,
    SponsorSideComponent,
    UserSettingsPanelComponent,
    MobileSidebarComponent,
    ProductCardComponent,
    CartSidebarComponent
  ],
  exports: [
    CommonModule,
    NgxExampleLibraryModule,
    HeaderComponent,
    SidenavComponent,
    CartSidebarComponent,
    SearchBarComponent,
    FooterComponent,
    SpinnerComponent,
    NgxScrollToFirstInvalidModule,
    LoadingPlaceholderComponent,
    CapitalizeFirstPipe,
    LazyLoadImageModule,
    NumbersOnlyDirective,
    AlphabetOnlyDirective,
    ShopCardComponent,
    UserSettingsPanelComponent,
    MobileSidebarComponent,
    ProductCardComponent,
  ],
  providers: [MessageService]

})

export class SharedModule {
}
