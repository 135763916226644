import { InjectionToken } from '@angular/core';

export let ROUTES_CONFIG = new InjectionToken('routes.config');

const basePaths = {
  home: '',
  auth: 'auth',
  user: 'user',
  heroes: 'heroes',
  shop: 'shop',
  cart: 'cart',
  ticket: 'tickets',
  locker: 'locker-room',
  wallOfFame: 'wall-of-fame',
  clubtv : 'club-tv',
  sponsor : 'sponsors',
  achievement : 'achievements',
  promotion : 'promotions',
  inbox : 'inbox',
  squad : 'squad'
};

const routesNames = {
  home: '',
  auth: {
    basePath: basePaths.auth,
  },
  shop: {
    basePath: basePaths.shop,
  },
  cart: {
    basePath: basePaths.cart,
  },
  ticket: {
    basePath: basePaths.ticket,
  },
  user: {
    basePath: basePaths.user,
  },
  locker: {
    basePath: basePaths.locker,
  },
  wallOfFame: {
    basePath: basePaths.wallOfFame,
  },
  clubtv: {
    basePath: basePaths.clubtv,
  },
  sponsor: {
    basePath: basePaths.sponsor,
  },
  achievement: {
    basePath: basePaths.achievement,
  },
  promotion: {
    basePath: basePaths.promotion,
  },
  inbox:{
    basePath: basePaths.inbox,
  },
  squad:{
    basePath: basePaths.squad,
  },
  
  error404: '404',
  heroes: {
    basePath: basePaths.heroes,
  },
};

export const RoutesConfig: any = {
  routesNames,
  routes: {
    home: `/${routesNames.home}`,
    error404: `/${routesNames.error404}`,
    heroes: {
      detail: getHeroDetail,
    },
  },
};

export function getHeroDetail(id) {
  return `/${basePaths.heroes}/${id}`;
}
