import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/modules/auth/shared/auth.service';

@Component({
  selector: 'app-wall-of-fame-layout',
  templateUrl: './wall-of-fame-layout.component.html',
  styleUrls: ['./wall-of-fame-layout.component.scss']
})
export class WallOfFameLayoutComponent implements OnInit, OnDestroy {
  username = null;
  subscription: Subscription;
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.subscription = this.authService.getLoginInfo().subscribe(username => {
      this.username = username;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
