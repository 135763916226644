import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'app-cart-sidebar',
  templateUrl: './cart-sidebar.component.html',
  styleUrls: ['./cart-sidebar.component.scss']
})
export class CartSidebarComponent implements OnInit {
  totalCost = 0;
  cartProducts = [];
  @Output() closeSidebar = new EventEmitter();
  constructor(public cartService: CartService) { }

  ngOnInit(): void {
    this.cartService.itemValue.subscribe((nextValue) => {
      if (nextValue) {
        this.cartProducts = nextValue;
        this.calculateCost();
      }
    });
  }

  calculateCost() {
    this.totalCost = 0;
    this.cartProducts.map(product => {
      this.totalCost += (product.quantily * product.price);
    });
  }
}
