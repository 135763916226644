import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent implements OnInit {
  type: string;
  title = '';
  content = '';
  design;
  contentStyle;
  cancelButtonState: boolean;
  constructor(
    private router: Router,
    public ref: DynamicDialogRef, public config: DynamicDialogConfig
  ) {
  }

  ngOnInit(): void {
    if (this.config.styleClass === 'error') {
      this.design = { color: 'danger', icon: 'times' };
      this.title = 'Error';
    }
    if (this.config.styleClass === 'success') {
      this.design = { color: 'success', icon: 'check' };
      this.title = 'Success';
    }
    if (this.config.styleClass === 'delete') {
      this.design = { color: 'secondary', icon: 'trash' };
      this.title = 'Delete This?';
    }
    if (this.config.styleClass === 'warning') {
      this.design = { color: 'warning', icon: 'warning' };
      this.title = 'Are you sure?';
    }
  }


  close(returnData) {
    if (returnData === 'ok') {
      this.ref.close();
      this.router.navigate([this.config.data.afterClose]);
    } else {
      this.ref.close();
    }

  }

}
